
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

nav {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  display: flex;
  position: absolute;

  .tile {
    width: 50%;
    margin: 0;
    padding: 20px;

    .spanner {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
    }
  }

  a {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $accent-color-inverse;
    font-size: 5vw;
    background: $accent-color;
    display: flex;
    position: absolute;
  }
}
