
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.meals-count-box {
  margin-top: -4em;
  margin-bottom: 3em;
  background: #fff;
  border: 1px solid $gray300;
  position: relative;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);

  &.disabled a {
    opacity: .4;
    pointer-events: none;
  }

  a {
    padding: 1em;
    color: $text-color;
    display: block;
  }

  .info {
    margin-bottom: 1em;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .icon {
      margin: 0 1em 0 0;
      flex-shrink: 0;
      width: 2.5em;
    }
    .left {
      flex-grow: 1;
    }

    h3 {
      margin: 0;
      font-size: 1.2em;
    }
    .items {
      margin: 0 1em 0 0;
      color: $gray600;

      @include loading;
    }

    .percentage {
      margin: 0;
      font-size: 2em;
      font-weight: 600;
      line-height: 100%;
    }
  }
}
