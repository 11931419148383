
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.nav-tile {
  flex-basis: 100%;
  margin: 0 .5em;
  background: #fff;
  border: 1px solid $gray300;
  position: relative;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);

  &.disabled {
    opacity: .4;
    pointer-events: none;
  }

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  a {
    padding: 1em;
    color: $text-color;
    display: block;
  }

  .icon {
    margin: 0 0 .5em;
    width: 2.5em;
  }

  .title {
    font-weight: 600;
    display: block;
  }
  .subtitle {
    min-width: 3em;
    min-height: 1em;
    color: $gray600;
    display: inline-block;

    @include loading;
  }
}
