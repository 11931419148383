
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.collapsible-box {
  max-height: 1000px;
  overflow-y: clip;
  transition: max-height .25s;

  &.collapsed {
    max-height: 0 !important;
  }
}
