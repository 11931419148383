
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.controls {
  margin: -1em -1em 0;
  padding: 1em;
  flex-direction: row;
  justify-content: space-between;
  background: $gray400;
  display: flex;

  select {
    width: auto;
  }

  /*button {
    width: auto;
    padding-left: 1em;
    padding-right: 1em;
    color: #fff;
    font-size: 1em;
    font-weight: normal;
    border: 1px solid #007115;
    background: #00b422;
  }*/
  button {
    width: auto;
  }
}
