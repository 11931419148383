
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.pagination {
  margin: 1em 0;
  text-align: center;

  button {
    margin: 0 5px;
    padding: 15px;
    color: #2d3748;
    font-weight: bold;
    cursor: pointer;
    border: 0;
    background: transparent;

    &[disabled] {
      opacity: .25;
      cursor: default;
    }
  }
}
