
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

  tr {
    user-select: none;

    td {
      background: #fff;

      @include generate(transition, background-color 2s);

      &.status {
        font-weight: normal;
      }

      a {
        color: #000;
      }
    }

    &.highlighted {
      td {
        background: #f8fa93;

        @include generate(transition, background-color .5s);
      }
    }
  }

  .return-button {
    padding: 0;
    border: 0;
    background: transparent;

    img {
      width: 1.25em;
    }
  }
