
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.log-entries {
  margin: 1em -1em;
  padding: 0;
  list-style: none;
  background: #fefefe;
  border-bottom: 1px solid $border-color;

  li {
    padding: 1em 1em 1em 4em;
    background: transparent no-repeat 1em 1em;
    background-size: 2em 2em;
    border-top: 1px solid $border-color;

    &.entry-type-available { background-image: url("@/assets/images/icon-available.svg"); }
    &.entry-type-return    { background-image: url("@/assets/images/icon-return.svg"); }
    &.entry-type-dispatch  { background-image: url("@/assets/images/icon-dispatch.svg"); }
    &.entry-type-dead      { background-image: url("@/assets/images/icon-dead.svg"); }
    &.entry-type-unknown   { background-image: url("@/assets/images/icon-unknown.svg"); }

    p.info {
      margin-bottom: 2px;
      color: $gray600;
      font-size: 90%;

      .author {
        margin: 0 15px 0 0;
        font-weight: bold;
      }
    }

    p.message {
      margin-top: 2px;
    }
    p.additional-message {
      margin-top: -13px;
      font-size: .9em;
      font-style: italic;
    }
  }
}
