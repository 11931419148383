
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

p {
  align-items: center;
  justify-content: space-between;
  display: flex;

  label {
    margin-right: 1em;
  }

  input {
    max-width: 300px;
    flex-grow: 1;
    margin-left: 0;
    margin-right: auto;
  }
}
