
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;1,400;1,600&display=swap');

@import "_mixins.scss";
@import "_variables.scss";

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: auto;
  color: $text-color;
  min-height: 100%;
  // user-select: none;
  background: #fff;
}

body {
  font-family: "Source Sans Pro", "Lucida Grande", Helvetica, Arial, sans-serif;
  background: $background-color;

  @media all and (orientation: portrait) {
    font-size: 3.5vw;
  }

  @media all and (orientation: landscape) {
    font-size: 14px;
  }
}



#app {
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  overflow-x: hidden;
  position: relative;
}


img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}


main {
  padding: 1em 1em 2em;
  flex-grow: 1;
  // overflow-y: auto;
  position: relative;
}

a {
  text-decoration: none;
  color: $link-color;

  &:hover {
    color: darken($link-color, 20%);
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 1em 0;
  font-weight: 600;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

h1, .h1 {
  font-size: 1.75em;
}

h2, .h2 {
  font-size: 1.5em;
}

h3, .h3 {
  font-size: 1.3em;
}

p {
  margin: 1em 0;

  &.center {
    text-align: center;
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

p.error {
  color: #a00;
  font-weight: bold;
}


p.empty {
  font-weight: bold;
}

.selectable {
  user-select: text;
}


progress {
  width: 100%;
  height: 1em;
  appearance: none;
  border-radius: .2em;
  border: 1px solid $border-color;
  overflow: hidden;

  &::-webkit-progress-value {
    @include generate(transition, all .5s);
  }
  &::-moz-progress-value {
    @include generate(transition, all .5s);
  }

  &::-webkit-progress-bar { background: $border-color; }
  &::-moz-progress-bar { background: $border-color; }

  &::-webkit-progress-value { background: $accent-color; }
  &::-moz-progress-value { background: $accent-color; }

  &.complete {
    &::-webkit-progress-value { background: #30be2e; }
    &::-moz-progress-value { background: #30be2e; }
  }

  &.overscanned {
    &::-webkit-progress-value { background: #a00; }
    &::-moz-progress-value { background: #a00; }
  }
}


.loading-animation {
  background: linear-gradient(90deg, $gray400 0%, $gray200 100%);
  animation: gradient 2s infinite;
  background-size: 200%;
  display: block;
}

@keyframes gradient {
  0% { background-position: 200% 0%; }
  50% { background-position: 0% 0%; }
  100% { background-position: 0% 0%; }
}



.jiggly {
  animation: jiggle 1s infinite;
}

@keyframes jiggle {
  // 0% { transform: rotate(.1deg); }
  // 50% { transform: rotate(-.1deg); }
  // 100% { transform: rotate(.1deg); }

  0% { transform: translate(-.05em, .1em) rotate(.25deg); }
  10% { transform: translate(.05em, .05em); }
  20% { transform: translate(-.1em, .05em); }
  30% { transform: translate(-.05em, .05em); }
  40% { transform: translate(.1em, -.05em); }
  50% { transform: translate(.05em, .1em); }
  60% { transform: translate(-.05em, .1em) rotate(-.25deg); }
  70% { transform: translate(.05em, .1em); }
  80% { transform: translate(.1em, -.1em); }
  90% { transform: translate(.05em, .1em); }
  100% { transform: translate(-.05em, .05em) rotate(.25deg); }
}


input[type=text],
input[type=number],
input[type=email],
input[type=phone],
input[type=password],
textarea,
select,
.generic-button {
  width: 100%;
  max-width: 500px;
  padding: 1em 1em;
  font-size: 1em;
  line-height: 1em;
  border: 1px solid $gray300;

  @include generate(appearance, none);

  &:active,
  &:focus {
    border-color: #120ce8;
    outline: 0;
  }
}

.generic-button {
  padding: 1em 1em;
  font-size: 1.15em;
  font-weight: 600;
  cursor: pointer;
  color: $accent-color-inverse;
  line-height: .75em;
  background: $accent-color;
  border: none;
  display: inline-block;

  &[disabled] {
    cursor: default;
    opacity: .5;
  }

  &.alternative {
    width: auto;
    font-size: 1em;
    padding: .5em 1em;
    color: $accent-alternative-color-inverse;
    background: $accent-alternative-color;
  }
}



table.generic-table {
  width: calc(100% + 2em);
  margin-left: -1em;
  margin-right: -1em;
  border-spacing: 0;
  border-collapse: collapse;

  thead {
    tr {
      th {
        padding: 1em 1em;
        color: #fff;
        font-weight: 400;
        text-align: left;
        background: #2d3748;

        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }

  tbody {
    tr {
      td, th {
        padding: 1em 1em;
        font-weight: 600;
        text-align: left;
        border-bottom: 1px solid $gray300;

        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }

    }
  }

  .select-multiple {
    width: 48px;
  }
}

main > table.generic-table:first-child {
  margin-top: -1em;
}



table.vertical-table {
  margin-left: -1em;
  margin-right: -1em;
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 1px solid $gray300;

  thead, tbody {
    tr {
      th {
        padding: 1em 1em;
        color: #fff;
        font-weight: 400;
        text-align: left;
        background: #2d3748;
        border-bottom: 1px solid $gray300;

        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }

      td {
        padding: 1em 1em;
        font-weight: 600;
        text-align: left;
        background: #fcfcfc;
        border-bottom: 1px solid $gray300;

        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }

  tbody {
    tr {
      th {
        text-align: right;
      }
    }
  }

  .select-multiple {
    width: 48px;
  }
}

main > table.vertical-table:first-child {
  margin-top: -1em;
}


.hide-small {
  @media all and (max-width: 400px) {
    display: none;
  }
}
