
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.item {
  margin: 0;
  padding: 1em 1em 1em 0;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid $gray300;
  display: flex;
  position: relative;

  @include generate(transition, background-color 2s);

  &:last-child {
    border-bottom: 0;
  }

  .image {
    width: 4em;
    height: 4em;
    margin-right: 1em;
    flex-shrink: 0;
    overflow: hidden;
    background: no-repeat center center;
    background-size: cover;

    @include generate(border-radius, 2em);
  }

  .info {
    p {
      margin: .5em 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .name {
      font-size: 1.1em;
    }

    .additional {
      color: $gray600;

      span {
        white-space: nowrap;

        &:after {
          margin: 0 .5em;
          content: "·";
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}


/*
.item {
  margin: .5em 0;
  padding: 1em;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid $gray300;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);
  background: #fff;
  display: flex;
  position: relative;

  @include generate(transition, background-color 2s);

  .image {
    width: 4em;
    height: 4em;
    margin-right: 1em;
    flex-shrink: 0;
    overflow: hidden;
    border: no-repeat center center;
    background-size: cover;

    @include generate(border-radius, 2em);
  }

  .info {
    p {
      margin: .5em 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .name {
      font-size: 1.1em;
    }

    .additional {
      color: $gray600;

      span {
        white-space: nowrap;

        &:after {
          margin: 0 .5em;
          content: "·";
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
*/

