
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

#top-bar {
  margin: 0;
  padding: 1em 0em;
  user-select: none;
  flex-shrink: 0;
  justify-content: space-between;
  background: #fff;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);
  display: flex;
  position: relative;

  .center {
    flex-grow: 3;
    flex-basis: 0;
    text-align: center;
    position: relative;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .left,
  .right {
    margin-left: 1em;
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
    z-index: 2;
    flex-direction: row;
    align-items: stretch;
    display: flex;
  }
  .right {
    margin-left: 0;
    margin-right: 1em;
    justify-content: flex-end;
  }

  .title {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    font-weight: 600;
  }
  .subtitle {
    margin: 0;
    padding: 0;
    color: $gray600;
  }

  a {
    color: #666;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  button {
    min-width: 3em;
    min-height: 3em;
    width: auto;
    padding: 0;
    font-size: 1em;
    justify-content: center;
    align-items: center;
    display: flex;
    background: transparent;
    border: 0;

    img {
      width: 1.75em;
      margin: 0;
    }
  }

  .logout {
    padding: 0 1em;

    img {
      width: 1.75em;
      margin: 0 0 0 0.5em;
    }
  }

  .back {
    min-width: 3em;
    min-height: 3em;

    img {
      width: 1.75em;
      margin: 0;
    }
  }
}
