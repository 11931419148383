
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.side-list {
  width: 100%;
  margin: 20px 0;
  padding: 0;
  justify-content: space-between;
  align-items: stretch;
  list-style: none;
  display: flex;
}
