
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

tr {
  user-select: none;
  position: relative;
  @include generate(transition, transform .25s);

  td {
    background: #fff;
    position: relative;

    @include generate(transition, background-color 2s);
  }

  &.highlighted {
    td {
      background: #f8fa93;

      @include generate(transition, background-color .5s);
    }
  }

  &.dispatch-button-visible {
    transform: translateX(-150px);
  }

}
.overriden {
  color: red;
}

.row-button {
  left: 100%;
  top: 0;
  bottom: 0;
  width: 150px;
  padding: 0;
  margin: 0;
  font-size: 1em;
  align-self: stretch;
  background: #00aa00;
  border: 0;
  overflow: hidden;
  transition: right .25s;
  position: absolute;

  &[disabled] {
    color: #222;
    background: #bbb;
  }
}

