
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.info {
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1em;
  color: #888;
  text-align: right;
  position: absolute;
  user-select: none;
}



.build-info {
  &:before {
    color: #bbb;
    content: "Build: ";
  }
}

.btn-reload {
  left: 0;
  bottom: 0;
  width: auto;
  padding: 1em;
  color: $link-color;
  font-size: 1em;
  font-weight: 400;
  border: 0;
  background: transparent;
  position: absolute;
}
