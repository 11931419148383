$gray200: #edf2f7;
$gray300: #E2E8F0;
$gray400: #CBD5E0;
$gray500: #a0aec0;
$gray600: #718096;
$gray800: #2d3748;

$accent-color: #FFB426;
$accent-color-active: lighten($accent-color, 20%);
$accent-color-inverse: #fff;

$accent-alternative-color: #3175e8;
$accent-alternative-color-inverse: #fff;

$background-color: #FAFAFC;
$border-color: #CBD5E0;
$text-color: $gray800;
$link-color: #3175e8;
