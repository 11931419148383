
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.item {
  margin: .5em 0;
  border: 1px solid $gray300;
  box-shadow: 0 1em 1em rgba(15, 13, 102, 0.04);
  background: #fff;
  position: relative;

  a {
    padding: 1em 3.7em 1em 1em;
    color: $text-color;
    background: transparent url("~@/assets/images/btn-next.svg") no-repeat center right 1.5em;
    background-size: .7em;
    display: block;
  }


  .status-marker {
    left: 0;
    top: 1em;
    bottom: 1em;
    width: .15em;
    background: #120ce8;
    position: absolute;
  }

  .name {
    margin: 0 1em 0 0;;
    font-size: 1.2em;
    font-weight: 600;
  }
  .count {
    padding-left: 1.3em;
    flex-shrink: 0;
    margin: 0;
    color: $gray600;
    background: transparent url("~@/assets/images/icon-food-container.svg") no-repeat left center;
    background-size: 1em;

    span {
      white-space: nowrap;
      display: inline-block;

      &:after {
        margin: 0 .5em;
        content: "·";
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
