
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.orders {
  margin: 1em -1em;
  padding: 0;
  list-style: none;
  background: #fefefe;
  border-bottom: 1px solid $border-color;

  li {
    padding: 1em;
    border-top: 1px solid $border-color;

    .field {
      margin-right: 15px;
      white-space: nowrap;
    }
  }
}
