
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.tip-bar {
  margin: -1em -1em 1em;
  padding: 1em;
  align-items: center;
  justify-content: space-between;
  background: $gray400;
  display: flex;

  &.warning {
    background: #d00;

    .messages {
      .message {
        color: #fff;
      }
      .submessage {
        color: #ddd;
      }
    }
  }

  .icon {
    width: 2em;
    height: 2em;
    margin: 0 1em;
    flex-grow: 0;
    flex-shrink: 0;

    img {
      margin: 0;
      width: 2em;
      height: 2em;
    }
  }

  .messages {
    margin: 0 1em;
    flex-grow: 1;

    p {
      margin: 0;
    }
    .message {
      font-size: 1.15em;
      font-weight: 600;
      color: $gray800;
    }
    .submessage {
      color: $gray600;
    }
  }
}


