
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.item {
  margin: 0;
  padding: 1em 1em 1em 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray300;
  display: flex;
  user-select: none;
  position: relative;

  @include generate(transition, 'background-color 2s, transform .25s');

  &:last-child {
    border-bottom: 0;
  }

  &.shifted {
    transform: translateX(-150px);
  }

  &.completed {
    .bags {
      color: green;
      font-weight: bold;
    }

    .image {
      border: 2px solid green;

      /*
      &:after {
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: "";
        background: rgba(0,155,0,.5);
        position: absolute;
      }*/
    }
  }

  .image {
    width: 4em;
    height: 4em;
    margin-right: 1em;
    flex-shrink: 0;
    overflow: hidden;
    background: no-repeat center center;
    background-size: cover;
    position: relative;
    border: 0px solid transparent;
    transition: all .5s;

    @include generate(border-radius, 2em);
  }

  .info {
    flex-grow: 1;
    margin: 0 1em 0 0;

    p {
      margin: .5em 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    .name {
      font-size: 1.1em;
    }

    .additional {
      color: $gray600;

      span {
        white-space: nowrap;
        display: inline-block;

        &:after {
          margin: 0 .5em;
          content: "·";
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .bags {
    flex-shrink: 0;

    span {
      text-align: right;
      display: block;
    }
  }

  .row-button {
    left: 100%;
    top: 0;
    bottom: 0;
    width: 150px;
    padding: 0;
    margin: 0 0 0 15px;
    font-size: 1em;
    align-self: stretch;
    background: #da0000;
    border: 0;
    overflow: hidden;
    transition: right .25s;
    position: absolute;

    img {
      width: 2.5em;
    }

    &[disabled] {
      color: #222;
      background: #bbb;
    }
  }

}

