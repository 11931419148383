
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.main-date-selector {
  margin: -1em -1em 0 -1em;
  color: #fff;
  position: relative;
}

.background-image {
  margin: 0;
  width: 100%;
  aspect-ratio: 375/214;
}

.content {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;

  h1 {
    margin: 0;
  }
  button {
    width: auto;
    padding-top: .5em;
    padding-bottom: .5em;
    padding-left: .5em;
    padding-right: 2em;
    margin-left: .5em;
    color: #fff;
    font-size: 1em;
    font-weight: 300;
    white-space: nowrap;
    background: rgba(255, 255, 255, .2) url("~@/assets/images/dropdown-arrow-white.svg") no-repeat right .5em center;
    background-size: .75em;
    border: 1px solid rgba(255, 255, 255, .5);
  }
}

@media all and (orientation: landscape) {
  .background-image {
    display: none;
  }

  .content {
    padding: 3em 0 7em;
    background: transparent url("~@/assets/images/bg-header.svg") no-repeat center center;
    background-size: cover;
    position: relative;
  }
}

.overlay {
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0,0,0,.5);
  z-index: 9998;
  opacity: 0;
  display: flex;
  position: fixed;

  @include generate(transition, "opacity .5s, left 0s .5s");

  &.visible {
    left: 0;
    opacity: 1;
    @include generate(transition, "opacity .5s, left 0s 0s");
  }
}

.popup {
  min-height: 300px;
  max-width: 80%;
  padding: 2em;
  margin-top: -200px;
  color: $text-color;
  background: #fff;
  position: relative;

  @include generate(transition, "opacity .5s, margin-top .5s");

  &.visible {
    margin-top: 50px;
    opacity: 1;
  }

  h3 {
    margin-top: 0;
    margin-right: 3em;
    line-height: 1em;
  }

  .close {
    top: .5em;
    right: .5em;
    margin: 0;
    padding: 1em;
    width: auto;
    background: transparent;
    border: 0;
    position: absolute;

    img {
      width: 1em;
    }
  }
}

.dates-list {
  max-height: 50vh;
  margin: 0 -2em;
  padding: 0;
  list-style: none;
  overflow-y: auto;

  li {

    button {
      width: 100%;
      padding: 1em 5em 1em 2em;
      color: $text-color;
      font-size: 1.2em;
      font-weight: 400;
      text-align: left;
      vertical-align: middle;
      background: transparent;
      border: 0;
      display: block;
      white-space: nowrap;
      border: 1px solid #fff;

      &.highlighted {
        background: #EDF2F7 url("~@/assets/images/icon-tick.svg") no-repeat right 1.5em center;
        background-size: 1em;
      }

      img {
        margin: -.2em .5em 0 0;
        width: 1em;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
}

.logout {
  right: 1em;
  top: 2em;
  padding: 0 1em;
  position: absolute;

  img {
    width: 1.75em;
  }
}
