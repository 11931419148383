
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.loading-indicator {
  left: 50%;
  top: -5em;
  width: 3em;
  height: 3em;
  margin-left: -1.5em;
  opacity: 0;
  background: #fff;
  border-radius: 50%;
  box-shadow: .25em .25em .5em rgba(0,0,0,.4);
  position: fixed;
  z-index: 10000;

  transition: top .5s .2s, opacity .5s .2s;

  &.visible {
    top: 1em;
    opacity: 1;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 3em;
  height: 3em;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 2em;
  height: 2em;
  margin: .5em;
  border: .3em solid #718096;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #718096 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
