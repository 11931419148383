@mixin generate($argument,$value) {
         #{$argument}: #{$value};
 -webkit-#{$argument}: #{$value};
    -moz-#{$argument}: #{$value};
     -ms-#{$argument}: #{$value};
      -o-#{$argument}: #{$value};
}


@mixin loading {
  &.loading {
    color: transparent;
    background: linear-gradient(90deg, $gray400 0%, $gray200 100%);
    animation: gradient 2s infinite;
    background-size: 200%;
    display: inline-block;
  }
}
