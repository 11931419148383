
          @import "@/assets/scss/_mixins.scss";
          @import "@/assets/scss/_variables.scss";
        

.search-bar {
  margin: 0 -1em;
  padding: .5em;
  background: $gray300;

  .search-term {
    max-width: 100%;
    padding-top: .5em;
    padding-bottom: .5em;
    line-height: 2.566em;
  }
}
